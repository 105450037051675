import { isValidElement } from "react";
import { v4 as uuidv4 } from "uuid";

const faqOneData = [
  {
    id: uuidv4(),
    ques: "What services do you provide?",
    ans: "We offer services in digital product development, technical services, UI/UX design, and e-commerce solutions.",
    index: "faq1",
  },

  {
    id: uuidv4(),
    ques: "How can you help my business?",
    ans: "We can help your business by providing expertise and support in the digital area, using technology to drive growth, enhance user experiences, and achieve your business goals.",
    index: "faq2",
  },

  {
    id: uuidv4(),
    ques: "Can Techno Austraisa handle both small and large-scale projects?",
    ans: "Yes, we have experience in handling projects of all sizes, from small-scale businesses to large-scale businesses.",
    index: "faq3",
  },

  {
    id: uuidv4(),
    ques: "Do you provide hosting and server management services?",
    ans: "We do offer hosting and server management services for the smooth operation and security of your digital solutions.",
    index: "faq4",
  },
];

export default faqOneData;
