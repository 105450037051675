import React from "react";
import logo1 from "../assets/img/home1/icons/logo1.svg";

const Preloader = () => {
  return (
    <>
      <div id="preloader" className="preloader">
        <div className="animation-preloader d-flex flex-column justify-content-center align-items-center">
          <div className="spinner" style={{ fontSize: "12px" }}></div>
          {/* <div className="txt-loading">
            <span data-text-preloader="B">T</span>
            <span data-text-preloader="U">E</span>
            <span data-text-preloader="S">C</span>
            <span data-text-preloader="I">H</span>
            <span data-text-preloader="C">N</span>
            <span data-text-preloader="O">O</span>
          </div> */}

          <img src={logo1} alt="Logo" width={130} />
          <p className="text-center text-[#19376D]">TECHNO AUSTRAISA</p>
        </div>
        <div className="loader">
          <div className="row">
            <div className="col-3 loader-section section-left">
              <div className="bg"></div>
            </div>
            <div className="col-3 loader-section section-left">
              <div className="bg"></div>
            </div>
            <div className="col-3 loader-section section-right">
              <div className="bg"></div>
            </div>
            <div className="col-3 loader-section section-right">
              <div className="bg"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Preloader;
