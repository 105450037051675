import { v4 as uuidv4 } from "uuid";

const workProcessData = [
  {
    id: uuidv4(),
    icon: "writing",
    title: "We Guide",
    desc: "We'll help you make smart decisions that align with your goals.",
  },

  {
    id: uuidv4(),
    icon: "flow-chart",
    title: "We Support",
    desc: "We are always here to support you throughout your digital journey",
  },

  {
    id: uuidv4(),
    icon: "interior-design",
    title: "We Execute",
    desc: "You can rely on us to make your projects come to life and get the results you're looking for.",
  },
];

export default workProcessData;
