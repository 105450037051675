import { v4 as uuidv4 } from "uuid";
import bg1 from "../../assets/img/services/1.jpg";

const servicesOneData = [
  // {
  //   id: uuidv4(),
  //   bg: bg1,
  //   icon: "home",
  //   title: "Managed Services",
  //   desc: "Our managed services provide flexible and tailored solutions ",
  // },
  // {
  //   id: uuidv4(),
  //   bg: bg1,
  //   icon: "blueprint",
  //   title: "Services Not-for-Profits",
  //   desc: " Virtual IT Group we develop a tailored technology solution .",
  // },
  {
    id: uuidv4(),
    bg: bg1,
    icon: "interior-design",
    title: "Digital Marketing",
    desc: " Driving online visibility and growth through effective digital marketing campaigns.",
  },
  {
    id: uuidv4(),
    bg: bg1,
    icon: "varnish",
    title: "Technical Services",
    desc: " Driving online visibility and growth through effective digital marketing campaigns.",
  },
  {
    id: uuidv4(),
    bg: bg1,
    icon: "varnish",
    title: (
      <p>
        UI/UX <br />
        Design
      </p>
    ),
    desc: "Creating visually appealing and user-friendly interfaces to enhance user experience.",
  },
  {
    id: uuidv4(),
    bg: bg1,
    icon: "varnish",
    title: "E-Commerce Marketing",
    desc: "Develop a complete e-commerce solution and establish a strong online presence for your business.",
  },

  // {
  //   id: uuidv4(),
  //   bg: bg1,
  //   icon: "interior-design",
  //   title: "Professional Services",
  //   desc: "Our team works closely with your business to ensure that the defined benefits are delivered.",
  // },

  // {
  //   id: uuidv4(),
  //   bg: bg1,
  //   icon: "varnish",
  //   title: "Development Services",
  //   desc: "Whether you need automation, integration or Artificial Intelligence rolled out in your business.",
  // },

  // {
  //   id: uuidv4(),
  //   bg: bg1,
  //   icon: "sketch-1",
  //   title: "Cloud Services",
  //   desc: "We combine our cloud expertise with Telstra’s own cloud infrastructure to manage your business cloud.",
  // },
  // {
  //   id: uuidv4(),
  //   bg: bg1,
  //   icon: "sketch-1",
  //   title: "Consulting Services",
  //   desc: "Our IT consultants have extensive experience across multiple sectors",
  // },
  // {
  //   id: uuidv4(),
  //   bg: bg1,
  //   icon: "sketch-1",
  //   title: "Security",
  //   desc: "We work to detect and respond to security threats so that you can conduct business activities with peace of mind.",
  // },
];

export default servicesOneData;
