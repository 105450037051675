import { Swiper, SwiperSlide } from "swiper/react";
// import slide1 from "../../assets/img/home1/slide1.jpg";
import Home2 from "../../assets/img/home1/Home2.jpg";
import Home from "../../assets/img/home1/Home.jpg";
import Hero from "../../assets/img/home1/hero.jpg";
import Hero2 from "../../assets/img/home1/hero2.jpg";
import bg from "../../assets/img/home1/bg.jpeg";

import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { Link } from "react-router-dom";

const HeroOne = () => {
  return (
    <section className="hero-wrapper hero-1">
      {/* <Swiper navigation={true} modules={[Navigation]} loop> */}
      <div className=" bg-cover" style={{ backgroundImage: `url(${Hero2})` }}>
        <div className="container">
          <div className="row">
            <div className="col-12 ps-md-5 pe-md-5 col-xxl-7 col-lg-10 col-md-8 col-sm-10">
              <div className="hero-contents pe-lg-3">
                <h1
                  className="fs-lg wow fadeInLeft animated"
                  data-wow-duration="1.3s"
                >
                  Grow Your Digital Presence With Our Vision & Technological
                  Expertise
                </h1>
                <p
                  className="pe-lg-5 wow fadeInLeft animated"
                  data-wow-duration="1.3s"
                  data-wow-delay=".4s"
                >
                  Whether you are a startup or an established company, Techno
                  Austraisa is here to help you on your digital journey.
                </p>
                <Link
                  to="/contact"
                  className="theme-btn me-sm-4 wow fadeInLeft"
                  data-wow-duration="1.2s"
                  data-wow-delay=".8s"
                >
                  contact us
                </Link>
                {/* <a
                  // href="about.html"
                  className="plus-text-btn wow fadeInLeft"
                  data-wow-duration="1.2s"
                  data-wow-delay=".6s"
                >
                  <div className="icon">
                    <i className="fas fa-plus"></i>
                  </div>
                  <div className="link-text">
                    <span>Explore</span> More about Us
                  </div>
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <SwiperSlide
          className=" bg-cover"
          style={{ backgroundImage: `url(${Home2})` }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 ps-md-5 pe-md-5 col-xxl-7 col-lg-8 col-md-8 col-sm-10">
                <div className="hero-contents pe-lg-3">
                  <h1
                    className="fs-lg wow fadeInLeft animated"
                    data-wow-duration="1.3s"
                  >
                    we build Your Dream
                  </h1>
                  <p
                    className="pe-lg-5 wow fadeInLeft animated"
                    data-wow-duration="1.3s"
                    data-wow-delay=".4s"
                  >
                    Busico is a construction and architecture environmentally
                    most responsible for any kinds of themes.
                  </p>
                  <a
                    href="contact.html"
                    className="theme-btn me-sm-4 wow fadeInLeft"
                    data-wow-duration="1.2s"
                    data-wow-delay=".8s"
                  >
                    contact us
                  </a>
                  <a
                    href="about.html"
                    className="plus-text-btn wow fadeInLeft"
                    data-wow-duration="1.2s"
                    data-wow-delay=".6s"
                  >
                    <div className="icon">
                      <i className="fas fa-plus"></i>
                    </div>
                    <div className="link-text">
                      <span>Explore</span> More about Us
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide> */}
      {/* </Swiper> */}
    </section>
  );
};

export default HeroOne;
