import React from "react";
import footerLogo from "../../assets/img/home1/icons/footerLogo.png";
import favico from "../../assets/img/home1/icons/favico.svg";
import ctaLogo from "../../assets/img/home1/icons/Artboard-1@4x.png";

const FooterCta = () => {
  return (
    <div className="footer-cta-wrapper">
      <div className="container">
        <div className="footer-cta-bg-wrapper">
          <div className="row justify-content-around align-items-center">
            <div className="col-lg-3 col-md-3 col-12">
              <div className="footer-logo">
                <a href="index.html">
                  <img
                    src={ctaLogo}
                    alt="logo"
                    style={{ width: "80%", marginLeft: "2rem" }}
                  />
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-5 ps-lg-0 offset-lg-1 col-12">
              <div className="footer-middle-text mt-4 mb-4 mt-md-0 mb-md-0 text-white">
                <p>
                  Techno Austraisa delivering exceptional digital solutions to
                  businesses nationwide and beyond.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-lg-4 col-12">
              <div className="footer-social-icon ms-md-5 text-lg-md-end">
                <a href="#">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="#">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="#">
                  <i className="fab fa-instagram"></i>
                </a>
                <a href="#">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterCta;
