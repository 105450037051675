import videoBg from "../../assets/img/video_bg_1.jpg";
import imgBlock1 from "../../assets/img/home1/img-block1.jpg";
import tabImg1 from "../../assets/img/home1/tab-img.jpg";
import { Link } from "react-router-dom";
import { useState } from "react";
import "react-modal-video/scss/modal-video.scss";
import potential from "../../assets/img/images/unlock-your-business-potential.png";
import planning from "../../assets/img/images/planning.png";
import developingImg from "../../assets/img/images/developing.png";
const AboutOne = ({ pt }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <section
        className={
          pt
            ? "about-section section-padding"
            : "about-section section-padding pt-0"
        }
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-5 col-12">
              <div className="img-block-pop-video">
                <img src={potential} alt="bussiness_potential" />
                {/* <div
                  className="popup-video-block d-flex justify-content-center align-items-center bg-cover"
                  style={{ backgroundImage: `url(${videoBg})` }}
                >
                  <div className="video-play-btn">
                    <span
                      className="popup-video"
                      onClick={() => setOpen(true)}
                      style={{ cursor: "pointer" }}
                    >
                      <i className="fas fa-play"></i>
                    </span>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-xl-6 col-lg-7 col-md-10 col-12">
              <div className="block-contents">
                <div className="section-title mt-4">
                  <h2>Unlock Your Business Potential</h2>
                </div>
                <p>
                  At Techno Austraisa we follow a highly competitive and
                  cooperative method to ensure that we provide successful
                  solutions developed to our customers' particular digital and
                  technological needs.
                </p>
              </div>
              <div className="tab-content-block">
                <ul
                  className="nav nav-pills mb-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="pills-technology-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-technology"
                      type="button"
                      role="tab"
                      aria-controls="pills-technology"
                      aria-selected="true"
                    >
                      Planning
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-materials-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-materials"
                      type="button"
                      role="tab"
                      aria-controls="pills-materials"
                      aria-selected="false"
                    >
                      Developing
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-approved-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-approved"
                      type="button"
                      role="tab"
                      aria-controls="pills-approved"
                      aria-selected="false"
                    >
                      Result
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-technology"
                    role="tabpanel"
                    aria-labelledby="pills-technology-tab"
                  >
                    <div className="tab-inner-contents">
                      <div className="img-box">
                        <img src={planning} alt="" />
                      </div>
                      <div className="checked-features-list">
                        <ul>
                          <li>Understand your project requirements</li>
                          <li>Create Roadmap</li>
                          <li>Project timelines and deadlines</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-materials"
                    role="tabpanel"
                    aria-labelledby="pills-materials-tab"
                  >
                    <div className="tab-inner-contents">
                      <div className="img-box">
                        <img src={developingImg} alt="" />
                      </div>
                      <div className="checked-features-list">
                        <ul>
                          <li>Create and Design</li>
                          <li>Execution of a secure digital solution</li>
                          <li>Deliver a product that meets your needs</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-approved"
                    role="tabpanel"
                    aria-labelledby="pills-approved-tab"
                  >
                    <div className="tab-inner-contents">
                      <div className="img-box">
                        <img src="assets/img/home1/tab-img.jpg" alt="" />
                      </div>
                      <div className="checked-features-list">
                        <ul>
                          <li>Delivering high-quality result</li>
                          <li>Ensure Ongoing Support</li>
                          <li>Satisfied Customers</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <Link to="/about" className="plus-text-btn mt-lg-5 mt-4">
                <div className="icon">
                  <i className="fas fa-plus"></i>
                </div>
                <div className="link-text">
                  <span>Explore</span> More about Us
                </div>
              </Link> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutOne;
