import { Link } from "react-router-dom";
import coreImg1 from "../../assets/img/home3/block1.png";
import coreImg2 from "../../assets/img/home3/block2.png";
import coreImg3 from "../../assets/img/home3/block3.png";
import coreImg4 from "../../assets/img/home3/block4.png";

const CoreFeaturesOne = () => {
  return (
    <section className="content-block-wrapper section-padding bg-circle fix">
      <div className="container">
        {/* block 1 */}
        <div className="row align-items-center">
          <div className="col-xl-6 col-lg-6 pe-xl-4 col-12">
            <div className="content-block-img">
              <img src={coreImg3} width={"80%"} alt="coreimg3" />
            </div>
          </div>
          <div className="col-xl-6 ps-xl-5 col-lg-6 col-12 mt-5 mt-lg-0">
            <div className="section-title-3">
              {/* <h6>core Services</h6> */}
              <h2>Digital Marketing</h2>
              <p>
                We offer comprehensive digital marketing services to help your
                business to establish a strong online presence, drive targeted
                traffic, and achieve its marketing goals. With Techno Austraisa
                as your digital marketing partner, you can elevate your online
                presence, reach your target audience effectively, and drive
                growth through effective digital marketing campaigns
              </p>
            </div>
            <div className="checked-features-list style-2">
              <p className="fw-bold my-2">
                {" "}
                Our range of digital marketing services includes the following:
              </p>
              <ul>
                <li>Search Engine Optimization (SEO)</li>
                <li>Social Media Marketing & Promotion</li>
                <li>Content Management</li>
                <li>Analytics and Reporting</li>
              </ul>
            </div>
          </div>
        </div>
        {/* block2 */}
        <div className="row align-items-center section-padding pb-0">
          <div className="col-xl-6 col-lg-6 col-12 mt-5 mt-lg-0 order-2 order-lg-1">
            <div className="section-title-3">
              {/* <h6>Core Services</h6> */}
              <h2>Technical Support</h2>
              <p>
                We offer a comprehensive range of technical services to support
                your digital initiatives and ensure the smooth functioning of
                your technology infrastructure. With Techno Austraisa's
                technical services, you can optimize your software systems, and
                enhance your overall technical capabilities. Our dedicated team
                of experts is committed to delivering reliable, scalable, and
                secure solutions that drive your business forward.
              </p>
            </div>
            <div className="checked-features-list style-2">
              <ul>
                <p className="fw-bold my-2">Our technical services include:</p>
                <li>Software Development</li>
                <li>Web Development</li>
                <li>Mobile App Development</li>
                <li>API Integration</li>
              </ul>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 ps-xl-5 col-12 order-1 order-lg-2">
            <div className="content-block-img">
              <img src={coreImg2} width={"80%"} alt="" />
            </div>
          </div>
        </div>
        {/* block 3 */}
        <div className="row align-items-center section-padding">
          <div className="col-xl-6 col-lg-6 pe-xl-4 col-12">
            <div className="content-block-img">
              <img src={coreImg1} width={"80%"} alt="coreimg1" />
            </div>
          </div>
          <div className="col-xl-6 ps-xl-5 col-lg-6 col-12 mt-5 mt-lg-0">
            <div className="section-title-3">
              {/* <h6>core Services</h6> */}
              <h2>UI/UX Design</h2>
              <p>
                We offer comprehensive UI/UX design services to create
                intuitive, user-friendly, and visually appealing digital
                experiences. With Techno Austraisa's UI/UX design services, you
                can create digital experiences that captivate your users, drive
                engagement, and differentiate your brand in the competitive
                digital landscape.
              </p>
            </div>
            <div className="checked-features-list style-2">
              <p className="fw-bold my-2">
                Our UI/UX design services encompass:
              </p>
              <ul>
                <li>Wireframing and Prototyping</li>
                <li>User Interface Design</li>
                <li>Interactive Design</li>
                <li>Usability Testing</li>
                <li>Responsive Design</li>
              </ul>
            </div>
          </div>
        </div>
        {/* block 4 */}
        <div className="row align-items-center  pb-0">
          <div className="col-xl-6 col-lg-6 col-12 mt-5 mt-lg-0 order-2 order-lg-1">
            <div className="section-title-3">
              {/* <h6>core Services</h6> */}
              <h2>Ecommerce</h2>
              <p>
                We provide comprehensive e-commerce services to help businesses
                establish and grow their online presence, optimize sales
                channels, and maximize revenue. With Techno Austraisa's
                e-commerce services, you can establish a robust online store,
                enhance customer experiences, and drive sales growth.
              </p>
            </div>
            <div className="checked-features-list style-2">
              <p className="fw-bold my-2">Our e-commerce services include:</p>
              <ul>
                <li>E-commerce Website Development</li>
                <li>Inventory Management</li>
                <li>Order Management</li>
                <li>Product Catalog Management</li>
                <li>Analytics and Reporting</li>
              </ul>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 ps-xl-5 col-12 order-1 order-lg-2">
            <div className="content-block-img">
              <img src={coreImg4} width={"85%"} alt="coreimg4" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CoreFeaturesOne;
