import React from "react";
import { Link } from "react-router-dom";
import img1 from "../../assets/img/project-tab.jpg";
import img2 from "../../assets/img/project-tab2.jpg";
import img3 from "../../assets/img/project-tab2.jpg";

import digitalImg from "../../assets/img/images/digital-marketing.png";
import technicalImg from "../../assets/img/images/technical-service.png";
import uiuxImg from "../../assets/img/images/ui-ux-designing.png";
import ecommerceImg from "../../assets/img/images/e-commerce.png";

function ProjectsTab() {
  return (
    // <section className="our-info-tabs-wrapper my-20">
    <div className="container mt-40 mb-40">
      <h1 className="text-center">Our Services</h1>
      <p className="text-center mb-4">
        At Techno Austraisa we provide digital services to businesses, guiding
        them towards success and providing innovative solutions on digital
        marketing, technical assistance and creating UI/UX designs.
      </p>
      <div className="row">
        <div className="col-12 col-xl-12">
          <div className="project-tabs-wrapper">
            <ul className="nav nav-pills" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="pills-tab1"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-tab-1"
                  type="button"
                  role="tab"
                  aria-controls="pills-tab-1"
                  aria-selected="true"
                >
                  <i className="flaticon-notebook"></i> Digital Marketing
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-tab2"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-tab-2"
                  type="button"
                  role="tab"
                  aria-controls="pills-tab-2"
                  aria-selected="false"
                >
                  <i className="flaticon-construction-tool-vehicle-with-crane-lifting-materials"></i>{" "}
                  Technical Services
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-tab3"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-tab-3"
                  type="button"
                  role="tab"
                  aria-controls="pills-tab-3"
                  aria-selected="false"
                >
                  <i className="flaticon-operation"></i> UI/UX Designing
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-tab4"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-tab-4"
                  type="button"
                  role="tab"
                  aria-controls="pills-tab-4"
                  aria-selected="false"
                >
                  <i className="flaticon-bar-chart"></i> E-commerce
                </button>
              </li>
            </ul>

            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-tab-1"
                role="tabpanel"
                aria-labelledby="pills-tab-1"
              >
                <div className="tab-content-wrapper">
                  <div className="row">
                    <div className="col-lg-6 col-12">
                      <h2>Digital Marketing</h2>
                      <p>
                        Driving online visibility and growth through effective
                        digital marketing campaigns.
                      </p>
                      <div className="checked-features-list style-2">
                        <ul>
                          <li>Search Engine Optimization (SEO)</li>
                          <li>Social Media Marketing & Promotion</li>
                          <li>Content Management</li>
                          <li>Analytics and Reporting</li>
                        </ul>
                      </div>
                      <Link to="/services" target="_top">
                        Read More <i className="fas fa-long-arrow-right"></i>
                      </Link>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="tab-img">
                        <img src={digitalImg} alt="digitalImg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-tab-2"
                role="tabpanel"
                aria-labelledby="pills-tab-2"
              >
                <div className="tab-content-wrapper">
                  <div className="row">
                    <div className="col-lg-6 col-12">
                      <h2>Technical Services</h2>
                      <p>
                        Provide a wide range of web development and software
                        development services.
                      </p>
                      <div className="checked-features-list style-2">
                        <ul>
                          <li>Software Development</li>
                          <li>Web Development</li>
                          <li>Mobile App Development</li>
                          <li>API Integration</li>
                        </ul>
                      </div>
                      <Link to="/services" target="_top">
                        Read More <i className="fas fa-long-arrow-right"></i>
                      </Link>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="tab-img">
                        <img src={technicalImg} alt="technicalImg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-tab-3"
                role="tabpanel"
                aria-labelledby="pills-tab-2"
              >
                <div className="tab-content-wrapper">
                  <div className="row">
                    <div className="col-lg-6 col-12">
                      <h2>UI/UX DESIGNING</h2>
                      <p>
                        Creating visually appealing and user-friendly interfaces
                        to enhance user experience.
                      </p>
                      <div className="checked-features-list style-2">
                        <ul>
                          <li>Wireframing and Prototyping</li>
                          <li>User Interface Design</li>
                          <li>Interactive Design</li>
                          <li>Usability Testing</li>
                          <li>Responsive Design</li>
                        </ul>
                      </div>
                      <Link to="/services" target="_top">
                        Read More <i className="fas fa-long-arrow-right"></i>
                      </Link>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="tab-img">
                        <img src={uiuxImg} alt="UIUXdesigm" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-tab-4"
                role="tabpanel"
                aria-labelledby="pills-tab-2"
              >
                <div className="tab-content-wrapper">
                  <div className="row">
                    <div className="col-lg-6 col-12">
                      <h2>E-COMMERCE</h2>
                      <p>
                        Develop a complete e-commerce solution and establish a
                        strong online presence for your business.
                      </p>
                      <div className="checked-features-list style-2">
                        <ul>
                          <li>E-commerce Website Development</li>
                          <li>Inventory Management</li>
                          <li>Order Management</li>
                          <li>Product Catalog Management</li>
                          <li>Analytics and Reporting</li>
                        </ul>
                      </div>
                      <Link to="/services" target="_top">
                        Read More <i className="fas fa-long-arrow-right"></i>
                      </Link>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="tab-img">
                        <img src={ecommerceImg} alt="ecommerce" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </section>
  );
}

export default ProjectsTab;
