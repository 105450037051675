import React from "react";
import FooterTwo from "../Components/Footer/FooterTwo";
import HeaderOne from "../Components/Header/HeaderOne";

import servicesbg from "../assets/img/home1/services.jpg";
import PageBanner from "../Components/PageBanner";
import ServicesOne from "../Components/Services/ServicesOne";
import ServicesCta from "../Components/Services/ServicesCta";
import BestFeatures from "../Components/Features/BestFeatures";
import Pricing from "../Components/Price/Pricing";
import FaqOne from "../Components/Faq/FaqOne";
import CoreFeaturesOne from "../Components/Features/CoreFeaturesOne";

const ServicePage = () => {
  return (
    <>
      <HeaderOne />
      <PageBanner
        title="Our Services"
        bannerBg={servicesbg}
        currentPage="Service"
      />
      <CoreFeaturesOne />
      {/* <ServicesOne /> */}
      {/* <ServicesCta /> */}
      {/* <BestFeatures /> */}
      {/* <Pricing /> */}
      {/* <FaqOne /> */}
      <FooterTwo />
    </>
  );
};

export default ServicePage;
