import React from "react";
import { Link } from "react-router-dom";
// import aboutImg from "../../assets/img/home2/about-cover.jpg";
import aboutImg from "../../assets/img/images/about-us.png";

const AboutTwo = () => {
  return (
    <section className="about-section section-padding">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-6 col-12 pe-xl-0">
            <div
              className="about-cover-bg bg-cover me-xl-5"
              style={{ backgroundImage: `url(${aboutImg})` }}
            >
              {/* <div className="our-experience-years">
                <h2 className="fs-1 fw-bold">30</h2>

                <p>
                  Years <span>Experience</span>
                </p>
              </div> */}
            </div>
          </div>
          <div className="col-xl-6 mt-5 mt-lg-0 col-12">
            <div className="block-contents">
              <div className="section-title">
                <span>About Company</span>
                <h1 className="fs-1">Unlock Your Business Potential</h1>
              </div>
              <blockquote>
                Our mission is to empower businesses with cutting-edge
                technology and drive their success in the digital landscape.
              </blockquote>
            </div>

            <p>
              Techno Austraisa is a technical agency based in Canberra,
              Australia, dedicated to delivering exceptional digital solutions
              to businesses nationwide and beyond.
            </p>
            <br />
            <p>
              With a team of highly skilled professionals, we specialize in
              digital marketing, technical services, UI/UX design, and
              e-commerce.
            </p>
          </div>
        </div>
      </div>
      <h2 className="section-rotate-title d-none d-xxl-block">ABOUT</h2>
    </section>
  );
};

export default AboutTwo;
