import React from "react";
import { Link } from "react-router-dom";
import FooterCta from "./FooterCta";
import FooterOneCopyrights from "./FooterOneCopyrights";
import footerMap from "../../assets/img/footer-map.png";

const FooterTwo = () => {
  return (
    <footer className="footer-2 footer-wrap">
      <div className="footer-widgets-wrapper text-white">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 pe-xl-0 col-sm-6 col-12">
              <div className="single-footer-wid site_info_widget">
                <div className="wid-title">
                  <h3>Get In Touch</h3>
                </div>
                <div className="contact-us">
                  <div className="single-contact-info">
                    <div className="icon">
                      <i className="fal fa-phone"></i>
                    </div>
                    <div className="contact-info">
                      <p>+61412773229</p>
                    </div>
                  </div>
                  <div className="single-contact-info">
                    <div className="icon">
                      <i className="fal fa-envelope"></i>
                    </div>
                    <div className="contact-info">
                      <p>inquiry@technoaustraisa.com.au</p>
                    </div>
                  </div>
                  <div className="single-contact-info">
                    <div className="icon">
                      <i className="fal fa-map-marker-alt"></i>
                    </div>
                    <div className="contact-info">
                      <p>
                        23-25 Lathlain Street, Belconnen <br /> 2617, Canberra,
                        Australia
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-6 offset-xl-1 col-xl-3 ps-xl-5 col-12">
              <div className="single-footer-wid">
                <div className="wid-title">
                  <h3>Quick Links</h3>
                </div>
                <ul>
                  <li>
                    <Link to="/about" target="_top">
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/services" target="_top">
                      Our Services
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact" target="_top">
                      Contact Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy" target="_top">
                      Privacy Policy
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-sm-6 col-xl-4 offset-xl-1 col-12">
              <div className="single-footer-wid newsletter_widget">
                <div className="wid-title">
                  <h3>Our Footprint</h3>
                </div>
                <div className="map-location">
                  <img src={footerMap} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FooterCta />
      <FooterOneCopyrights />
    </footer>
  );
};

export default FooterTwo;
