import React from "react";
import icon1 from "../assets/img/icons/t1.png";
import icon2 from "../assets/img/icons/t2.png";

const Timeline = () => {
  return (
    <section className="company-timeline-wrapper section-padding">
      <div className="container">
        <div className="row mtm-40">
          <div className="col-lg-4  col-md-6 offset-lg-1 pe-lg-0">
            <div className="timeline-content text-lg-end">
              <div className="single-timeline-box">
                <span>0 - 3 Months</span>
                <h6>Startup Up</h6>
                <p>
                  We started by setting up the company, defined our vision,
                  mission, goals and services, built a team, and established an
                  online presence.
                </p>
              </div>
              <div className="single-timeline-box">
                <span>3 - 6 months</span>
                <h6>Business Development</h6>
                <p>
                  Then we actively moved on towards seeking clients, showcasing
                  our expertise, and focused on gaining partnerships.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-2 d-none mt-40 d-lg-block">
            <div className="time-line-icon">
              <div className="single-icon">
                <img src={icon1} alt="" />
              </div>
              <div className="single-icon">
                <img src={icon2} alt="" />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 ps-lg-0">
            <div className="timeline-content">
              <div className="single-timeline-box">
                <span>6 - 12 months </span>
                <h6>Project Execution and Delivery </h6>
                <p>
                  We have successfully executed client projects, delivered
                  high-quality solutions, and made sure that our clients are
                  100% satisfied.
                </p>
              </div>
              <div className="single-timeline-box">
                <span>12+ months</span>
                <h6>Growth and Expansion</h6>
                <p>
                  Scaling the team, expanding service offerings, nurturing
                  client relationships, and seeking opportunities for growth and
                  strategic partnerships.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-lg-5 mt-3">
          <div className="col-md-6 col-12 col-lg-4">
            <div className="single-icon-border-box">
              <div className="icon">
                <i className="flaticon-trust"></i>
              </div>
              <div className="content">
                <h6>Experienced Team</h6>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12 col-lg-4">
            <div className="single-icon-border-box">
              <div className="icon">
                <i className="flaticon-cashier-machine"></i>
              </div>
              <div className="content">
                <h6>Stable & Reliable</h6>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12 col-lg-4">
            <div className="single-icon-border-box">
              <div className="icon">
                <i className="flaticon-goal"></i>
              </div>
              <div className="content">
                <h6>Clear Visibility</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Timeline;
