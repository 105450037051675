import React from "react";
import HeaderOne from "../Components/Header/HeaderOne";
import FooterTwo from "../Components/Footer/FooterTwo";

const PrivacyPolicy = () => {
  return (
    <>
      <HeaderOne />

      <section>
        <div className="container mt-20">
          {/* TERMS CONTENT */}
          <div className="d-flex justify-content-center">
            <div className="col-lg-10">
              {/* TERMS TITLE */}
              <div
                className="text-center"
                //  style={{marginTop:"8rem"}}
              >
                {/* Title */}
                {/* <h2 className="h2-md">Our Terms &amp; Privacy</h2> */}
                <h2 className="h2-md">Terms &amp; Privacy</h2>
              </div>
              {/* <hr className="divider" /> */}
              {/* TERMS BOX */}
              <div className="terms-box mt-60">
                {/* Title */}
                {/* <h5 className="h5-xl">Reservation of Rights</h5> */}
                {/* Text */}
                <p className="p-lg">
                  At Techno Austraisa, we are committed to protecting the
                  privacy and confidentiality of the personal information you
                  provide to us. This Privacy Policy outlines how we collect,
                  use, disclose, and safeguard your information when you visit
                  our website or engage with our services. By accessing our
                  website and providing your personal information, you consent
                  to the practices described in this Privacy Policy
                </p>
                {/* Text */}
              </div>{" "}
              {/* END TERMS BOX */}
              {/* TERMS BOX */}
              <div className="terms-box">
                {/* Title */}
                <h5 className="h5-xl">Information Collection</h5>
                {/* Text */}

                <p className="p-lg">
                  We may collect personal information, such as your name, email
                  address, phone number, and any other information you
                  voluntarily provide to us through our website's contact forms
                  or when you communicate with us. We may also collect
                  non-personal information, such as your IP address and browsing
                  behaviour, using cookies and similar technologies.
                </p>
              </div>{" "}
              <div className="terms-box">
                {/* Title */}
                <h5 className="h5-xl">Use of Information</h5>
                {/* Text */}

                <p className="p-lg">
                  We use the collected information to understand your needs,
                  respond to your inquiries, provide the requested services,
                  improve our website and services, and communicate with you. We
                  may also use your information for marketing purposes, such as
                  sending you relevant updates, promotions, or newsletters, with
                  the option to unsubscribe at any time.
                </p>
              </div>{" "}
              {/* END TERMS BOX */}
              {/* TERMS BOX #3 */}
              <div className="terms-box">
                {/* Title */}
                <h5 className="h5-xl">Information Sharing:</h5>
                {/* Text */}
                <p className="p-lg">
                  We do not sell, trade, or rent your personal information to
                  third parties. However, we may share your information with
                  trusted service providers who assist us in operating our
                  website, conducting our business, or servicing you, as long as
                  they agree to keep your information confidential and comply
                  with applicable data protection laws.
                </p>

                {/* Text */}
              </div>{" "}
              <div className="terms-box">
                {/* Title */}
                <h5 className="h5-xl">Data Security</h5>
                {/* Text */}
                <p className="p-lg">
                  We implement reasonable security measures to protect your
                  personal information from unauthorized access, disclosure,
                  alteration, or destruction. However, no method of transmission
                  over the Internet or electronic storage is 100% secure, and we
                  cannot guarantee absolute security.
                </p>
              </div>{" "}
              <div className="terms-box">
                {/* Title */}
                <h5 className="h5-xl">External Links</h5>
                {/* Text */}
                <p className="p-lg">
                  Our website may contain links to third-party websites. We are
                  not responsible for the privacy practices or content of those
                  websites. We encourage you to review the privacy policies of
                  any third-party websites you visit.
                </p>
              </div>{" "}
              <div className="terms-box">
                {/* Title */}
                <h5 className="h5-xl">Changes to the Privacy Policy</h5>
                {/* Text */}
                <p className="p-lg">
                  We reserve the right to modify or update this Privacy Policy
                  anytime. Any changes will be effective immediately upon
                  posting the revised Privacy Policy on our website. Please
                  periodically review this Privacy Policy for any updates.
                </p>
              </div>{" "}
              <div className="terms-box">
                {/* Title */}
                <h5 className="h5-xl">Contact Us</h5>
                {/* Text */}
                <p className="p-lg">
                  If you have any questions or concerns about our Privacy Policy
                  or the handling of your personal information, please contact
                  us at support@technoaustraisa.com.au
                </p>
              </div>
              <div className="text-center mb-40 ">
                <p style={{ fontStyle: "italic", fontWeight: 500 }}>
                  This Privacy Policy applies solely to information collected
                  through our website and services and does not apply to
                  information collected offline or through other channels.
                </p>
              </div>
            </div>{" "}
            {/* END TERMS CONTENT */}
          </div>{" "}
          {/* End row */}
        </div>{" "}
        {/* End container */}
      </section>
      {/* END TERMS & PRIVACY */}
      <FooterTwo />
    </>
  );
};

export default PrivacyPolicy;
